import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Head from "../components/head";
import blogStyles from "../components/blog.module.scss";

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPosts(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            id
            title
            slug
            project {
              id
            }
            image {
              file {
                url
              }
            }
            publishedDate(formatString: "MMMM Do, YYYY")
          }
        }
      }
    }
  `);


  return (
    <div>
      <Layout>
        <Head title="Blog" />
        <div class="section-page-header">
          <h2>Blog</h2>
        </div>
        <ol className={blogStyles.posts}>
          {data.allContentfulPosts.edges.map(edge => {
            var pagePath = edge.node.project
              ? "../project/" + edge.node.slug
              : "../blog/" + edge.node.slug;
            return (
              <li>
                <Link to={pagePath}>
                  <div class="row">
                    <div class="col-md-2">
                      <img
                        src={`${edge.node.image.file.url}`}
                        alt={`${edge.node.title}`}
                        className={blogStyles.shadow}
                      />
                    </div>
                    <h3 className={blogStyles.title}>{edge.node.title}</h3>
                    {/* <span className={blogStyles.date}>{edge.node.publishedDate}</span> */}
                    <p className={blogStyles.post}></p>
                  </div>
                </Link>
              </li>
            );
          })}
        </ol>
      </Layout>
    </div>
  );
};

export default BlogPage;
